import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button, Dialog, DialogContent, DialogActions, Grid, DialogContentText
} from '@mui/material';
import { toWithOutError, formatAllNumber } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import SuccessDialog from '../../components/Dialogs/SuccessDialog';
import DeleteDialog from '../../components/Dialogs/DeleteDialog';
import { Delete, Edit } from '@mui/icons-material/';
import { mainBlue, lightBlue, grayColor } from 'variables/colors';
import EditOrAddFieldsDialog from '../../components/Dialogs/EditOrAddFieldDialog';
import { getIdToEdit, getMethodPayFromPayout, getPayIdFieldToEdit, getPayoutById } from "utils/payouts.utils";
import { payoutDeleteRedux } from "redux/actions/PayoutsActions";
import { addMpIdSubtitle, completePayoutSubtitle } from "utils/textToShow.utils";
import { payoutCompleteRequestRedux, payoutEditRedux } from '../../redux/actions/PayoutsActions';
import { getUserAvailableMoney } from "services/BackendCommunication";
import CopyableEditText from "components/TextField/CopyableEditText";
import { PAYPAL } from "variables/financial";

const PayoutActionsDialog = (props) => {

  const { isOpen, handleClose, payoutId } = props;

  const dispatch = useDispatch();
  const payouts = useSelector(store => store.payouts.payouts);

  const payout = getPayoutById(payouts, payoutId);
  const userFromPayout = { id: payout.ownerId, email: payout.ownerEmail };
  const methodPay = getMethodPayFromPayout(payout);

  const loadingFinancialDataState = { payed: "...", royalties: "...", available: "...", lastRequest: "...", taxesUsd: "..." };
  // const errorFinancialDataState = { payed: "ERROR", royalties: "ERROR", available: "ERROR", lastRequest: "ERROR", taxesUsd: "ERROR" };
  const [financialInfo, setFinancialInfo] = useState(loadingFinancialDataState)

  const [loading, setLoading] = useState(false);
  const [buttonState, setButtonState] = useState('none');
  const [completePayoutState, setCompletePayoutState] = useState('none');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState({ open: false, title: "", subtitle: [""], initialValues: "", values: "" });

  useEffect(() => {
    const getUserFinancialInfo = async () => {
      let { payed, royalties, available, lastRequest, taxesUsd } = await getUserAvailableMoney(userFromPayout, dispatch);
      setFinancialInfo({ payed, royalties, available, lastRequest, taxesUsd });
    }

    if (isOpen && payout.ownerId) {
      getUserFinancialInfo();
    }
  }, [payout])

  let successDialogTitle = "Pago actualizado correctamente.";
  let successDialogText = ["Se le envío un Email al usuario para notificarlo."];

  const handleCloseDelete = () => setOpenDeleteDialog(false);

  const handleDelete = async () => {
    setLoading(true);
    let result = await toWithOutError(dispatch(payoutDeleteRedux(payoutId)));
    if (result === "ERROR") { setButtonState("error"); setLoading(false); }
    else { setLoading(false); setOpenDeleteDialog(false); handleCloseActions(); }
  }

  const handleAddPayId = () => setOpenEditDialog({
    open: true, title: "ID del Pago o Cupón", subtitle: completePayoutSubtitle, handleConfirm: (payId) => handleCompletePayout(payId),
    initialValues: "", values: "", handleCloseDialog: handleCloseEditDialog, type: ''
  });

  const handleAddMpId = () => setOpenEditDialog({
    open: true, title: "ID de Mercado Pago del Pago", subtitle: addMpIdSubtitle, handleConfirm: (mpId) => handleAddMpIdPayout(mpId),
    initialValues: "", values: "", handleCloseDialog: handleCloseEditDialog, type: ''
  });

  const handleEditId = () => setOpenEditDialog({
    open: true, title: "Editar ID", subtitle: "", handleConfirm: (newPayId) => handleEditPayId(newPayId),
    initialValues: payout[getPayIdFieldToEdit(payout)], values: "", handleCloseDialog: handleCloseEditDialog, type: ''
  });

  const handleEditPayId = async newPayId => {
    setButtonState("loading");
    let payIdField = getPayIdFieldToEdit(payout);
    let editResult = await toWithOutError(dispatch(payoutEditRedux({ ...payout, [payIdField]: newPayId })));
    if (editResult === "ERROR") { setButtonState("error"); return; }
    setButtonState("none");
    setOpenEditDialog({ open: false, title: "", subtitle: "", values: "" });
  }

  const handleAddMpIdPayout = async mpId => {
    setButtonState("loading");
    let editResult = await toWithOutError(dispatch(payoutEditRedux({ ...payout, mpId })));
    if (editResult === "ERROR") { setButtonState("error"); return; }
    setButtonState("none");
    setOpenEditDialog({ open: false, title: "", subtitle: "", values: "" });
  }

  const handleCompletePayout = async payId => {
    setButtonState("loading");
    let editResult = await toWithOutError(dispatch(payoutCompleteRequestRedux(payout, payId)));
    if (editResult === "ERROR") { setButtonState("error"); return; }
    setButtonState("none");
    setOpenEditDialog({ open: false, title: "", subtitle: "", values: "" });
  }

  const handleCloseEditDialog = () => {
    setOpenEditDialog({ open: false, title: "", subtitle: [""], values: "" });
  }

  const handleCloseActions = () => {
    setFinancialInfo(loadingFinancialDataState);
    handleClose();
  }

  const jsxUserName = <DialogContentText key={'user name'}>
    Nombre Usuario: <b>{`${payout.userName} ${payout.userLastName}`}</b>
  </DialogContentText>

  const jsxOwnerEmail = <DialogContentText key={'owner email'}>
    Email de Usuario: <b>{payout.ownerEmail}</b>
  </DialogContentText>

  const jsxCuit = <DialogContentText key={'cuit'}>
    CUIT: <b>{`${payout.userCuit}`}</b>
  </DialogContentText>

  const jsxCbu = <DialogContentText key={'cbu'}>
    CBU/CVU: <b>{`${payout.cbuCvuAlias}`}</b>
  </DialogContentText>

  const jsxOtherPayId = <DialogContentText key={'coelsa-id'}>
    {`${payout.cbuCvuAlias ? "COELSA ID: " : "Cupón ID: "}`} <b>{`${payout.otherPayId}`}</b>
  </DialogContentText>

  const jsxPayPalEmail = <DialogContentText key={'paypal-email'}>
    Email de Paypal: <b>{`${payout.paypalEmail}`}</b>
  </DialogContentText>

  const jsxPayPalId = <DialogContentText key={'paypal-id'}>
    ID de Paypal: <b>{`${payout.paypalId ? payout.paypalId : "no provisto"}`}</b>
  </DialogContentText>

  const jsxWiseEmail = <DialogContentText key={'payoneer-email'}>
    Email de Wise: <b>{`${payout.payoneerEmail}`}</b>
  </DialogContentText>

  const jsxPayoutId = <DialogContentText key={'pay-id'}>
    ID del pago en la APP: <b>{`${payout.id}`}</b>
  </DialogContentText>

  const jsxMpId = <DialogContentText key={'mp-id'}>
    Id de Mercado Pago: <b>{`${payout.mpId ? payout.mpId : "completar"}`}</b>
  </DialogContentText>

  const jsxRedCripto = <DialogContentText key={'red-cripto'}>
    Red Cripto: <b>{`${payout.userCuit ? payout.userCuit : "no provista"}`}</b>
  </DialogContentText>

  // Hasta que haga la refactorizacion del doc de PAYOUT: Payoneer email sera address cripto cuando sea cripto.
  const jsxAddressCripto = <DialogContentText key={'address-cripto'}>
    Dirección Cripto: <b>{`${payout.payoneerEmail ? payout.payoneerEmail : "no provista"}`}</b>
  </DialogContentText>

  return (
    <>
      <SuccessDialog isOpen={completePayoutState !== 'none' && completePayoutState !== 'processing'} title={successDialogTitle}
        contentTexts={successDialogText} handleClose={() => setCompletePayoutState('none')}
        successImageSource="/images/success.jpg" size="sm" />

      <DeleteDialog isOpen={openDeleteDialog} setIsOpen={setOpenDeleteDialog} handleClose={handleCloseDelete}
        title={"Eliminar Solicitud"} textName={payout.ownerEmail} textContent={''}
        deleteAction={handleDelete} deleteButtonText={"Eliminar"} openLoader={loading} buttonState={'delete'}
      />

      <EditOrAddFieldsDialog loading={buttonState === "loading"}
        buttonState={buttonState} type={""} editOptions={openEditDialog} setEditOptions={setOpenEditDialog} />

      <Dialog
        maxWidth="sm"
        id="info-dialog"
        open={isOpen}
        onClose={handleCloseActions}>

        <DialogContent>
          <DialogContentText key={'subtitle'}>
            <p style={{ fontSize: "20px" }}><b>Información del Pago</b></p>
          </DialogContentText>

          {payout.userName && <CopyableEditText textToCopy={payout.userName} color='grey' jsxElementText={jsxUserName} />}
          {payout.ownerEmail && <CopyableEditText textToCopy={payout.ownerEmail} color='grey' jsxElementText={jsxOwnerEmail} />}
          {payout.userCuit && methodPay !== 'Cripto' && <CopyableEditText textToCopy={payout.userCuit} color='grey' jsxElementText={jsxCuit} />}
          {payout.cbuCvuAlias && <CopyableEditText textToCopy={payout.cbuCvuAlias} color='grey' jsxElementText={jsxCbu} />}
          {payout.otherPayId && <CopyableEditText textToCopy={payout.otherPayId} color='grey' jsxElementText={jsxOtherPayId} />}
          {payout.paypalEmail && payout.paymentMethod === PAYPAL && <CopyableEditText textToCopy={payout.paypalEmail} color='grey' jsxElementText={jsxPayPalEmail} />}
          {payout.paypalId && payout.paymentMethod === PAYPAL && <CopyableEditText textToCopy={payout.paypalId} color='grey' jsxElementText={jsxPayPalId} />}
          {payout.payoneerEmail && methodPay !== 'Cripto' && <CopyableEditText textToCopy={payout.payoneerEmail} color='grey' jsxElementText={jsxWiseEmail} />}
          {payout.id && <CopyableEditText textToCopy={payout.id} color='grey' jsxElementText={jsxPayoutId} />}
          {methodPay === 'Depósito' && <CopyableEditText textToCopy={payout.mpId ? payout.mpId : "completar"} color='grey' jsxElementText={jsxMpId} />}
          {methodPay === 'Payoneer' && <CopyableEditText textToCopy={payout.mpId ? payout.mpId : "completar"} color='grey' jsxElementText={jsxMpId} />}
          {methodPay === 'Cripto' && <CopyableEditText textToCopy={payout.userCuit} color='grey' jsxElementText={jsxRedCripto} />}
          {methodPay === 'Cripto' && <CopyableEditText textToCopy={payout.payoneerEmail} color='grey' jsxElementText={jsxAddressCripto} />}


          {methodPay === "Payoneer" && <DialogContentText key={'amount-to-transfer-payoneer'} paddingTop={2}>
            Pedido: <b>{`${payout.currency === "USD" ? formatAllNumber(payout.transferTotalUsd, 2, '.') + ' USD' : formatAllNumber(payout.transferTotalAskedCurrency, 2, '.') + ' ARS'}`}</b>
          </DialogContentText>}

          <DialogContentText key={'amount-to-transfer'} paddingTop={2}>
            A pagar: <b>{`${payout.currency === "USD"
              ? formatAllNumber((methodPay === "Payoneer" || methodPay === "Cripto" || methodPay === "Wise") ? payout.transferTotalUsd * .95 : payout.transferTotalUsd, 2, '.') + ' USD'
              : formatAllNumber(payout.transferTotalAskedCurrency, 2, '.') + ' ARS'}`}</b>
          </DialogContentText>

          <DialogContentText sx={{ paddingTop: 1 }} key={'royalties'}>
            Regalías generadas por el Usuario: <b>{`${financialInfo?.royalties} USD`}</b>
          </DialogContentText>

          <DialogContentText key={'taxes'}>
            Impuestos del Usuario: <b>{`${financialInfo?.taxesUsd} USD`}</b>
          </DialogContentText>

          <DialogContentText key={'withdawals'}>
            Dinero retirado por el Usuario: <b>{`${financialInfo?.payed} USD`}</b>
          </DialogContentText>

          <DialogContentText sx={{ paddingTop: 1, textDecoration: 'underline' }} key={'available money'}>
            Dinero disponible del Usuario: <b>{`${financialInfo?.available} USD`}</b>
          </DialogContentText>

          <Grid container direction="column" paddingTop={2}>

            <Grid item xs={6} padding={1}>
              <Button
                onClick={handleAddPayId}
                sx={{
                  backgroundColor: payout.status === "REQUESTED" ? mainBlue : grayColor[11], color: 'white',
                  '&:hover': { backgroundColor: payout.status === "REQUESTED" ? lightBlue : grayColor[11] }
                }}
                endIcon={<Edit />}
                disabled={payout.status !== "REQUESTED"}
                fullWidth>
                {payout.status === "REQUESTED" ? `Completar Pago: ${methodPay}` : "Pago Realizado"}
              </Button>
            </Grid>

            {methodPay === 'Depósito' && <Grid item xs={6} padding={1}>
              <Button
                onClick={handleAddMpId}
                sx={{
                  backgroundColor: !payout.mpId ? mainBlue : grayColor[11], color: 'white',
                  '&:hover': { backgroundColor: !payout.mpId ? lightBlue : grayColor[11] }
                }}
                endIcon={<Edit />}
                disabled={payout.mpId !== ""}
                fullWidth>
                {!payout.mpId ? "Agregar MP ID" : "MP ID Agregado"}
              </Button>
            </Grid>}

            <Grid item xs={6} padding={1}>
              <Button
                onClick={handleEditId}
                sx={{ backgroundColor: mainBlue, color: 'white', '&:hover': { backgroundColor: lightBlue } }}
                endIcon={<Edit />}
                fullWidth>
                {getIdToEdit(payout)}
              </Button>
            </Grid>

            <Grid item xs={6} padding={1}>
              <Button
                onClick={() => setOpenDeleteDialog(true)}
                sx={{ backgroundColor: '#c20202', color: 'white', '&:hover': { backgroundColor: '#c20202' } }}
                endIcon={<Delete />}
                fullWidth>
                Eliminar
              </Button>
            </Grid>

          </Grid>

        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseActions} color="primary">
            Atrás
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default PayoutActionsDialog;

PayoutActionsDialog.defaultProps = {
  isOpen: false,
}

PayoutActionsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  payoutId: PropTypes.string.isRequired,
}