import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Grid, Skeleton } from '@mui/material';
import dayjs from 'dayjs';
import CalendarInput from 'components/Input/CalendarInput';
import { updateLoginOTPUserData } from 'services/BackendCommunication';
import { useDispatch } from 'react-redux';

const UpdateLoginOTPDialog = ({ open, onClose, userId }) => {

  const dispatch = useDispatch();
  const [otpCode, setOtpCode] = useState('');
  const [expiryDate, setExpiryDate] = useState(dayjs());

  useEffect(() => {
    if (open && userId) {
      setOtpCode(generateOTP());
      setExpiryDate(dayjs().add(7, 'day')); // Si no tiene fecha, por defecto 7 días
    }
  }, [open, userId]);

  // Función para generar un OTP aleatorio de 6 dígitos
  const generateOTP = () => {
    return Math.floor(100000 + Math.random() * 900000).toString();
  };

  const handleConfirm = async () => {
    // Aca va la lógica para actualizar el OTP desde el backend
    const updateOTPDataResponse = await updateLoginOTPUserData(userId, otpCode, expiryDate.valueOf(), dispatch);
    if (updateOTPDataResponse === "SUCCESS") { }
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth sx={{ textAlign: 'center' }}>
      <DialogTitle>Actualizar OTP de Usuario</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          {/* Campo de OTP */}
          <Grid item xs={6}>
            <TextField
              sx={{ width: '75%' }}
              label="Código OTP"
              variant="outlined"
              value={otpCode}
              onChange={(e) => setOtpCode(e.target.value)}
              inputProps={{ maxLength: 6 }}
            />
          </Grid>

          {/* Selector de Fecha de Expiración */}
          <Grid item xs={6}>
            <CalendarInput
              selectedDate={expiryDate}
              label="Fecha de Vencimiento"
              onChangeDate={setExpiryDate}
              withTimeSelection={true}
              width={'75%'}
              maxDate={dayjs().add(3, 'year')}
              minDate={dayjs().add(1, 'day')}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'space-around' }}>
        <Button onClick={onClose} color="primary" variant="outlined">
          Atrás
        </Button>
        <Button onClick={handleConfirm} color="primary" variant="contained">
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateLoginOTPDialog;
